<template>
  <div class="Productbox">
    <div class="headerbox">
    </div>
    <div class="center">
      <div class="typebox">
        <div class="itemtype  point" :class="{'actype':typindex===item.id}" v-for="item in typelist" :key="item.id" @mouseenter="typeclick(item.id)">
          <img class="img" v-show="imgshow" :src="item.img" alt="">
          <transition name="fade" mode="out-in">
            <img class="img" v-show="imgshow1 &&typindex===item.id" :src="item.bimg" alt="">
          </transition>
          <span class="typetitle">{{item.title}}</span>
        </div>

      </div>
      <div class="imginfo">
        <img v-show="t1 " src="../../assets/images/all/pc-product-t1-1.png" alt="">
        <img v-show="t2 " src="../../assets/images/all/pc-product-t2-2.png" alt="">
        <img v-show="t3 " src="../../assets/images/all/pc-product-t3-3.png" alt="">
        <img v-show="t4 " src="../../assets/images/all/pc-product-t4-4.png" alt="">
        <img v-show="t5 " src="../../assets/images/all/pc-product-t5-5.png" alt="">
      </div>
      <div class="titlechange" id="titlechange">
        <div class="titleitem point" :class="{'actitle':titleindex===index}" v-for="item , index in titlelist" :key="item.id" @click="titleclick(index,item.id)">
          {{item.name}}
        </div>
      </div>
      <div class="goodsbox">
        <div class="goodsitem point" v-for="item in goodslist" :key="item.id" @click="godetaile(item)">
          <img :src="onlineUrl+item.images[0]" alt="">
          <span class="goodstitle">{{item.title}}</span>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagechange">
        <van-pagination class="commonpage" v-model="currentPage" :total-items="total" :items-per-page="per_page"  force-ellipses @change="changepage">
          <template #prev-text>
            <img class="prev-l prev-no-dis" src="../../assets/images/all/pc-productd-r.png" alt="">
            <img class="prev-l prev-dis" src="../../assets/images/all/pc-icon18.png" alt="">
          </template>
          <template #next-text>
            <img class="prev-r prev-no-dis" src="../../assets/images/all/pc-productd-r.png" alt="">
            <img class=" prev-r prev-dis" src="../../assets/images/all/pc-icon18.png" alt="">
          </template>
        </van-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getgoods_categoryApi, getgoods_listApi } from '../../network/api';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      typelist: [
        {
          id: 1,
          img: require('../../assets/images/all/pc-product-t1-s1.png'),
          bimg: require('../../assets/images/all/pc-product-t1.png'),
          title: 'LVT Click'
        },
        {
          id: 2,
          img: require('../../assets/images/all/pc-product-t2-s2.png'),
          bimg: require('../../assets/images/all/pc-product-t2.png'),
          title: 'Loose lay'
        },
        {
          id: 3,
          img: require('../../assets/images/all/pc-product-t3-s3.png'),
          bimg: require('../../assets/images/all/pc-product-t3.png'),
          title: 'Dry back'
        },
        {
          id: 4,
          img: require('../../assets/images/all/pc-product-t4-s4.png'),
          bimg: require('../../assets/images/all/pc-product-t4.png'),
          title: 'WPC'
        },
        {
          id: 5,
          img: require('../../assets/images/all/pc-product-t5-s5.png'),
          bimg: require('../../assets/images/all/pc-product-t5.png'),
          title: 'SPC'
        },
      ],
      imgshow: true,
      imgshow1: true,
      typindex: 1,
      t1: true,
      t2: false,
      t3: false,
      t4: false,
      t5: false,

      titlelist: [],
      titleindex: 0,
      titleid: null,
      goodslist: [],
      currentPage: 1,
      total: 10,
      per_page: 12,

    };
  },
  computed: {
    ...mapState(["onlineUrl"]),
  },
  created() {
    this.gettypedata();

  },
  methods: {
    godetaile(v) {
      this.$router.push(
        {
          path: "/goodsDetaile",
          query: {
            id: v.id
          }
        }
      );
    },
    backTop() {
      const scrollTop = document.getElementById("titlechange");
      scrollTop.scrollIntoView();
    },
    changepage(v) {
      this.currentPage = v;
      this.getgoodslistdata();
      this.backTop();
    },
    async gettypedata() {
      const res = await getgoods_categoryApi();
      this.titlelist = res.data;
      this.titleid = res.data[0].id;
      this.getgoodslistdata();
    },
    async getgoodslistdata() {
      let params = {
        category_id: this.titleid,
        page: this.currentPage

      };
      const res = await getgoods_listApi(params);
      console.log(res);
      this.goodslist = res.data.data;
      this.total = res.data.total;
      this.per_page = res.data.per_page;
    },
    titleclick(v, id) {
      this.titleindex = v;
      this.titleid = id;
      this.getgoodslistdata();
    },
    typeclick(v) {
      this.typindex = v;
      this.imgshow1 = this.imgshow;
      switch (this.typindex) {
        case 1:
          this.t1 = true;
          this.t2 = false;
          this.t3 = false;
          this.t4 = false;
          this.t5 = false;
          break;
        case 2:
          this.t1 = false;
          this.t2 = true;
          this.t3 = false;
          this.t4 = false;
          this.t5 = false;
          break;
        case 3:
          this.t1 = false;
          this.t2 = false;
          this.t3 = true;
          this.t4 = false;
          this.t5 = false;
          break;
        case 4:
          this.t1 = false;
          this.t2 = false;
          this.t3 = false;
          this.t4 = true;
          this.t5 = false;
          break;
        case 5:
          this.t1 = false;
          this.t2 = false;
          this.t3 = false;
          this.t4 = false;
          this.t5 = true;
          break;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.Productbox {
  overflow: hidden;
  .headerbox {
    width: 19.2rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/all/pc-Product-title.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.6rem;
    .centertext {
      width: 6.97rem;
      height: 1.65rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      font-family: Paytone One, Paytone One;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.9);
      position: relative;
      .line {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      .line2 {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .center {
    width: 13rem;
    margin: 0 auto;
    .typebox {
      display: flex;
      gap: 0;
      .itemtype {
        width: 1.62rem;
        height: 1.72rem;
        overflow: hidden; /* 隐藏超出容器的部分 */
        position: relative; /* 为了绝对定位图片 */
        flex: 1;
        transition: all 0.8s;
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .typetitle {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          font-size: 0.24rem;
          bottom: 0;
          width: 100%;
          height: 50%;
          color: #fff;
        }
      }

      .actype {
        flex: 4;
        overflow: unset;
        img {
          width: 100%;
          height: 100%;
        }
        .typetitle {
          font-size: 0.28rem;
          justify-content: flex-start;
          margin-left: 0.6rem;
        }
      }
    }
    /* 定义 fade 类名及其动画效果 */
    .fade-enter-active {
      animation: fadeIn 0.5s; /* 渐显动画 */
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.25;
      }
      50% {
        opacity: 0.5;
      }
      75% {
        opacity: 0.75;
      }
      100% {
        opacity: 1;
      }
    }

    .imginfo {
      margin-top: 0.6rem;
      width: 100%;
      margin-bottom: 0.6rem;
      height: 5rem;
      img {
        width: 100%;
      }
    }
    .titlechange {
      display: flex;
      margin-bottom: 0.6rem;
      .titleitem {
        font-size: 0.2rem;
        border: 0.02rem solid #015f3d;
        padding: 0.15rem 0.23rem;
        border-radius: 0.34rem;
        margin-right: 0.24rem;
        box-shadow: 0 0.04rem 0.08rem 0 #c9dfd7;
      }
      .actitle {
        background: #005e3c;
        color: #fff;
      }
    }
    .goodsbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: left;
      .goodsitem {
        width: 3.8rem;
        height: 3.18rem;
        position: relative;
        margin-bottom: 0.4rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .goodstitle {
          width: 3.8rem;
          height: 0.88rem;
          text-align: center;
          line-height: 0.88rem;
          color: #fff;
          font-size: 0.22rem;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      /* 如果最后一行是3个元素 */
      .goodsitem:last-child:nth-child(3n - 1) {
        margin-right: calc(34.5% + 3% / 3);
      }
    }
    .pagechange {
      margin-bottom: 0.6rem;
    }
  }
}
</style>